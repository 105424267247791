import {
  RULE_TARGET,
  PERuleCategoriesAll,
  BEFORE_OR_AFTER_FINAL_PRICE,
  PEMIRuleCategoriesDisplay,
} from '@shared/constants';
import {
  PAR_PRICE_ROUNDING,
  FEE_RULE_ENHANCEMENTS,
} from '@shared/constants/switches.js';

import {
  CLEAR_RULE_DETAILS,
  SET_CLAMP_CATEGORY,
  SET_IS_INVALID,
  SET_IS_INVALID_NAME,
  SET_IS_NEW_ITEM,
  SET_IS_RULE_SAVING,
  SET_IS_UPDATE_NEEDED,
  SET_RULE,
  SET_TARGET,
  SET_UPDATED_GRIDS,
  SET_MIN_STRUCTURE,
  SET_MAX_STRUCTURE,
  SET_IS_ACTIVE,
  SET_NAME,
  SET_CATEGORY,
  SET_SUBCATEGORY,
  SET_IS_INCLUDED_IN_RATE_SHEET_GENERATION,
  SET_USAGE,
  SET_NOTES,
  SET_IS_INCLUDED_IN_APR,
  SET_FEE_PAID_BY,
  SET_FEE_PAID_TO,
  SET_HUD_LINE_NUMBER,
  SET_IS_HIDDEN_ADJUSTMENT,
  SET_IS_PROFITABILITY_RULE,
  SET_IS_NEGATIVE_BOOLEAN_EQUATION,
  SET_IS_EXCLUDED_FROM_CLAMPING,
  SET_RULE_FIELDS,
  SET_ACCURACY,
  SET_ROUNDING_TYPE,
  SET_RULE_WORKFLOWS_FOR_LOCK_ACTION,
  SET_TARGET_TYPE,
  SET_UP_TO_PRICE,
  SET_DOWN_TO_PRICE,
  PAR_ROUNDING_FORM_INVALID,
  SET_EXCLUDED_FROM_CLAMPING_TYPE,
  SET_IS_READ_ONLY,
  SET_IS_INCLUDED_IN_POINTS_AND_FEES,
} from './mutationTypes.js';
import clone from 'lodash/cloneDeep';

import {
  convertResultStructureToBasisPoints,
  PEClampType,
  isRoundingRule,
} from '@pe/components/Configure/RuleDetails/RulesLogic.js';
import { ENABLE_COMPLIANCE_RULES_UI } from '@shared/constants/flags';

function initialState() {
  return {
    isActive: false,
    name: undefined,
    category: undefined,
    subCategory: undefined,
    isIncludedInRateSheetGeneration: false,
    usage: undefined,
    notes: '',
    isHiddenAdjustment: false,
    feePaidBy: undefined,
    feePaidTo: undefined,
    hudLineNumber: undefined,
    isIncludedInApr: true,
    isIncludedInPointsAndFees: false,
    isProfitabilityRule: false,
    isNegativeBooleanEquation: false,
    isExcludedFromClamping: false,
    excludedFromClampingType: BEFORE_OR_AFTER_FINAL_PRICE.BEFORE,
    clampCategory: null,
    isReadOnly: true,
    isInvalid: false,
    isInvalidName: false,
    isNewItem: false,
    isRuleSaving: false,
    isUpdateNeeded: true,
    rule: null,
    target: RULE_TARGET.PRICE,
    updatedGrids: {},
    maxStructure: [],
    minStructure: [],
    ruleFields: {},
    workflowRules: null,
    // undefined is the initial state of a value not set, meant to restart form flow
    // null is deliberate to reset a field, but not restarting form flow.
    accuracy: { final: undefined, current: undefined },
    roundingType: { final: undefined, current: undefined },
    targetType: { final: undefined, current: undefined },
    upToPrice: { final: undefined, current: undefined },
    downToPrice: { final: undefined, current: undefined },
    parRoundingFormInvalid: undefined,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    isActive: state => state.isActive,
    name: state => state.name,
    category: state => state.category,
    subCategory: state => state.subCategory,
    isIncludedInRateSheetGeneration: state =>
      state.isIncludedInRateSheetGeneration,
    usage: state => state.usage,
    notes: state => state.notes,
    isHiddenAdjustment: state => state.isHiddenAdjustment,
    isIncludedInApr: state => state.isIncludedInApr,
    isIncludedInPointsAndFees: state => state.isIncludedInPointsAndFees,
    feePaidTo: state => state.feePaidTo,
    feePaidBy: state => state.feePaidBy,
    hudLineNumber: state => state.hudLineNumber,
    isProfitabilityRule: state => state.isProfitabilityRule,
    isNegativeBooleanEquation: state => state.isNegativeBooleanEquation,
    isExcludedFromClamping: state => state.isExcludedFromClamping,
    excludedFromClampingType: state =>
      state.excludedFromClampingType || BEFORE_OR_AFTER_FINAL_PRICE.BEFORE,
    clampCategory: state => state.clampCategory,
    isInvalid: state => state.isInvalid,
    isInvalidName: state => state.isInvalidName,
    isNewItem: state => state.isNewItem,
    isReadOnly: state => state.isReadOnly,
    isRuleSaving: state => state.isRuleSaving,
    isRuleStatementReadOnly: (_, getters) => getters.isReadOnly,
    isUpdateNeeded: state => state.isUpdateNeeded,
    rule: state => state.rule,
    target: state => state.target,
    updatedGrids: state => state.updatedGrids,
    maxStructure: state => state.maxStructure,
    minStructure: state => state.minStructure,
    ruleFields: state => state.ruleFields,
    workflowRules: state => state.workflowRules,
    ruleCategories(state, getters, rootState, rootGetters) {
      const ruleCategories = {
        Eligibility: 'Eligibility',
        Fee: 'Fee',
        Adjustment: 'Adjustment',
        LockDesk: 'LockDesk',
        Margin: 'Margin',
        Clamp: 'Clamp',
        SRP: 'SRP',

        EligibilityMatrix: 'EligibilityMatrix',
      };

      if (rootGetters['core/isSwitchActive'](PAR_PRICE_ROUNDING)) {
        ruleCategories.Rounding = 'Rounding';
      }

      if (rootGetters['core/isFlagActive'](ENABLE_COMPLIANCE_RULES_UI)) {
        ruleCategories.Compliance = 'Compliance';
      }
      return ruleCategories;
    },
    ruleCategoriesDisplay(state, getters, rootState, rootGetters) {
      const ruleCategoriesDisplay = {
        [PERuleCategoriesAll]: 'All',
        [getters.ruleCategories.Adjustment]: 'LLPA',
        [getters.ruleCategories.LockDesk]: 'Lock Desk',
        [getters.ruleCategories.Margin]: 'Margin',
        [getters.ruleCategories.SRP]: 'SRP',
        [getters.ruleCategories.Fee]: 'Fee',
        [getters.ruleCategories.Eligibility]: 'Eligibility',
        [getters.ruleCategories.EligibilityMatrix]: 'Eligibility',
        [getters.ruleCategories.Clamp]: 'Min/Max',
        ...PEMIRuleCategoriesDisplay,
      };

      if (rootGetters['core/isSwitchActive'](PAR_PRICE_ROUNDING)) {
        ruleCategoriesDisplay[getters.ruleCategories.Rounding] = 'Rounding';
      }

      if (rootGetters['core/isFlagActive'](ENABLE_COMPLIANCE_RULES_UI)) {
        ruleCategoriesDisplay[getters.ruleCategories.Compliance] = 'Compliance';
      }
      return ruleCategoriesDisplay;
    },
    isRoundingRuleCategory:
      (state, getters, rootState, rootGetters) => ruleCategory =>
        rootGetters['core/isSwitchActive'](PAR_PRICE_ROUNDING) &&
        isRoundingRule(ruleCategory),
    feeRuleEnhancementsEnabled(state, getters, rootState, rootGetters) {
      return rootGetters['core/isSwitchActive'](FEE_RULE_ENHANCEMENTS);
    },
    accuracy: state =>
      state.accuracy.current === undefined
        ? state.accuracy.final
        : state.accuracy.current,
    roundingType: state =>
      state.roundingType.current === undefined
        ? state.roundingType.final
        : state.roundingType.current,
    targetType: state =>
      state.targetType.current === undefined
        ? state.targetType.final
        : state.targetType.current,
    upToPrice: state =>
      state.upToPrice.current === undefined
        ? state.upToPrice.final
        : state.upToPrice.current,
    downToPrice: state =>
      state.downToPrice.current === undefined
        ? state.downToPrice.final
        : state.downToPrice.current,
    parRoundingFormInvalid: state => state.parRoundingFormInvalid,
  },
  mutations: {
    [CLEAR_RULE_DETAILS](state) {
      Object.assign(state, initialState());
    },
    [SET_IS_ACTIVE](state, isActive) {
      state.isActive = isActive;
    },
    [SET_NAME](state, name) {
      state.name = name;
    },
    [SET_CATEGORY](state, category) {
      state.category = category;
    },
    [SET_IS_INCLUDED_IN_RATE_SHEET_GENERATION](
      state,
      isIncludedInRateSheetGeneration,
    ) {
      state.isIncludedInRateSheetGeneration = isIncludedInRateSheetGeneration;
    },
    [SET_USAGE](state, usage) {
      state.usage = usage;
    },
    [SET_NOTES](state, notes) {
      state.notes = notes;
    },
    [SET_IS_INCLUDED_IN_APR](state, isIncludedInApr) {
      state.isIncludedInApr = isIncludedInApr;
    },
    [SET_IS_INCLUDED_IN_POINTS_AND_FEES](state, isIncludedInPointsAndFees) {
      state.isIncludedInPointsAndFees = isIncludedInPointsAndFees;
    },
    [SET_FEE_PAID_BY](state, feePaidBy) {
      state.feePaidBy = feePaidBy;
    },
    [SET_FEE_PAID_TO](state, feePaidTo) {
      state.feePaidTo = feePaidTo;
    },
    [SET_HUD_LINE_NUMBER](state, hudLineNumber) {
      state.hudLineNumber = hudLineNumber;
    },
    [SET_IS_HIDDEN_ADJUSTMENT](state, isHiddenAdjustment) {
      state.isHiddenAdjustment = isHiddenAdjustment;
    },
    [SET_IS_PROFITABILITY_RULE](state, isProfitabilityRule) {
      state.isProfitabilityRule = isProfitabilityRule;
    },
    [SET_IS_NEGATIVE_BOOLEAN_EQUATION](state, isNegativeBooleanEquation) {
      state.isNegativeBooleanEquation = isNegativeBooleanEquation;
    },
    [SET_IS_EXCLUDED_FROM_CLAMPING](state, isExcludedFromClamping) {
      state.isExcludedFromClamping = isExcludedFromClamping;
    },
    [SET_EXCLUDED_FROM_CLAMPING_TYPE](state, excludedFromClampingType) {
      state.excludedFromClampingType = excludedFromClampingType;
    },
    [SET_CATEGORY](state, category) {
      state.category = category;
    },
    [SET_SUBCATEGORY](state, subCategory) {
      state.subCategory = subCategory;
    },
    [SET_CLAMP_CATEGORY](state, clampCategory) {
      state.clampCategory = clampCategory;
    },
    [SET_IS_INVALID](state, isInvalid) {
      state.isInvalid = isInvalid;
    },
    [SET_IS_INVALID_NAME](state, isInvalidName) {
      state.isInvalidName = isInvalidName;
    },
    [SET_IS_NEW_ITEM](state, isNewItem) {
      state.isNewItem = isNewItem;
    },
    [SET_IS_RULE_SAVING](state, isRuleSaving) {
      state.isRuleSaving = isRuleSaving;
    },
    [SET_IS_READ_ONLY](state, isReadOnly) {
      state.isReadOnly = isReadOnly;
    },
    [SET_IS_UPDATE_NEEDED](state, isUpdateNeeded) {
      state.isUpdateNeeded = isUpdateNeeded;
    },
    [SET_RULE](state, rule) {
      state.rule = rule;
    },
    [SET_TARGET](state, target) {
      state.target = target;
    },
    [SET_UPDATED_GRIDS](state, grids) {
      state.updatedGrids = grids;
    },
    [SET_MIN_STRUCTURE](state, structure) {
      state.minStructure = structure;
    },
    [SET_MAX_STRUCTURE](state, structure) {
      state.maxStructure = structure;
    },
    [SET_RULE_FIELDS](state, ruleFields) {
      state.ruleFields = ruleFields;
    },
    [SET_ACCURACY](state, accuracy) {
      state.accuracy = accuracy;
    },
    [SET_ROUNDING_TYPE](state, roundingType) {
      state.roundingType = roundingType;
    },
    [SET_RULE_WORKFLOWS_FOR_LOCK_ACTION](state, workflow) {
      state.workflowRules = workflow;
    },
    [SET_TARGET_TYPE](state, targetType) {
      state.targetType = targetType;
    },
    [SET_UP_TO_PRICE](state, price) {
      state.upToPrice = price;
    },
    [SET_DOWN_TO_PRICE](state, price) {
      state.downToPrice = price;
    },
    [PAR_ROUNDING_FORM_INVALID](state, invalid) {
      state.parRoundingFormInvalid = invalid;
    },
  },
  actions: {
    setWorkflowRules({ commit }, workflow) {
      commit(SET_RULE_WORKFLOWS_FOR_LOCK_ACTION, workflow);
    },
    clearRuleDetails({ commit }) {
      commit(CLEAR_RULE_DETAILS);
    },
    setName({ commit }, name) {
      commit(SET_NAME, name);
    },
    setIsActive({ commit }, isActive) {
      commit(SET_IS_ACTIVE, isActive);
    },
    setCategory({ commit }, category) {
      commit(SET_CATEGORY, category);
    },
    setIsIncludedInRateSheetGeneration(
      { commit },
      isIncludedInRateSheetGeneration,
    ) {
      commit(
        SET_IS_INCLUDED_IN_RATE_SHEET_GENERATION,
        isIncludedInRateSheetGeneration,
      );
    },
    setUsage({ commit }, usage) {
      commit(SET_USAGE, usage);
    },
    setNotes({ commit }, notes) {
      commit(SET_NOTES, notes);
    },
    setIsHiddenAdjustment({ commit }, isHiddenAdjustment) {
      commit(SET_IS_HIDDEN_ADJUSTMENT, isHiddenAdjustment);
    },
    setFeePaidBy({ commit }, feePaidBy) {
      commit(SET_FEE_PAID_BY, feePaidBy);
    },
    setFeePaidTo({ commit }, feePaidTo) {
      commit(SET_FEE_PAID_TO, feePaidTo);
    },
    setHUDLineNumber({ commit }, hudLineNumber) {
      commit(SET_HUD_LINE_NUMBER, hudLineNumber);
    },
    setIsIncludedInApr({ commit }, isIncludedInApr) {
      commit(SET_IS_INCLUDED_IN_APR, isIncludedInApr);
    },
    setIsIncludedInPointsAndFees({ commit }, isIncludedInPointsAndFees) {
      commit(SET_IS_INCLUDED_IN_POINTS_AND_FEES, isIncludedInPointsAndFees);
    },
    setIsProfitabilityRule({ commit }, isProfitabilityRule) {
      commit(SET_IS_PROFITABILITY_RULE, isProfitabilityRule);
    },
    setIsNegativeBooleanEquation({ commit }, isNegativeBooleanEquation) {
      commit(SET_IS_NEGATIVE_BOOLEAN_EQUATION, isNegativeBooleanEquation);
    },
    setIsExcludedFromClamping({ commit }, isExcludedFromClamping) {
      commit(SET_IS_EXCLUDED_FROM_CLAMPING, isExcludedFromClamping);
    },
    setExcludedFromClampingType({ commit }, excludedFromClampingType) {
      commit(SET_EXCLUDED_FROM_CLAMPING_TYPE, excludedFromClampingType);
    },

    setClampCategory({ commit, state }, clampCategory) {
      if (clampCategory !== PEClampType.Margin) {
        const notValid = [null, undefined, '', []];
        if (!notValid.includes(state.minStructure[0]?.content)) {
          const structure = clone(state.minStructure);
          convertResultStructureToBasisPoints(structure);
          commit(SET_MIN_STRUCTURE, structure);
        }
        if (!notValid.includes(state.maxStructure[0]?.content)) {
          const structure = clone(state.maxStructure);
          convertResultStructureToBasisPoints(structure);
          commit(SET_MAX_STRUCTURE, structure);
        }
      }
      commit(SET_CLAMP_CATEGORY, clampCategory);
    },
    setIsInvalid({ commit }, isInvalid) {
      commit(SET_IS_INVALID, isInvalid);
    },
    setIsInvalidName({ commit }, isInvalidName) {
      commit(SET_IS_INVALID_NAME, isInvalidName);
    },
    setIsNewItem({ commit }, isNewItem) {
      commit(SET_IS_NEW_ITEM, isNewItem);
    },
    setIsRuleSaving({ commit }, isRuleSaving) {
      commit(SET_IS_RULE_SAVING, isRuleSaving);
    },
    setIsUpdateNeeded({ commit }, isUpdateNeeded) {
      commit(SET_IS_UPDATE_NEEDED, isUpdateNeeded);
    },
    setRule({ commit }, rule) {
      commit(SET_RULE, rule);
    },
    setTarget({ commit }, target) {
      commit(SET_TARGET, target);
    },
    setUpdatedGrids({ commit }, grids) {
      commit(SET_UPDATED_GRIDS, grids);
    },
    setMinStructure({ commit }, structure) {
      if ([null, undefined, ''].includes(structure[0]?.content)) {
        structure = [];
      }
      commit(SET_MIN_STRUCTURE, structure);
    },
    setMaxStructure({ commit }, structure) {
      if ([null, undefined, ''].includes(structure[0]?.content)) {
        structure = [];
      }
      commit(SET_MAX_STRUCTURE, structure);
    },
    setRuleFields({ commit }, ruleFields) {
      commit(SET_RULE_FIELDS, ruleFields);
    },
    setAccuracy({ commit, state }, accuracy) {
      commit(SET_ACCURACY, { ...state.accuracy, current: accuracy });
    },
    setRoundingType({ commit, state }, roundingType) {
      commit(SET_ROUNDING_TYPE, {
        ...state.roundingType,
        current: roundingType,
      });
    },
    setTargetType({ commit, state }, targetType) {
      commit(SET_TARGET_TYPE, { ...state.targetType, current: targetType });
    },
    setUpToPrice({ commit, state }, price) {
      commit(SET_UP_TO_PRICE, { ...state.upToPrice, current: price });
    },
    setDownToPrice({ commit, state }, price) {
      commit(SET_DOWN_TO_PRICE, { ...state.downToPrice, current: price });
    },
    setParRoundingFormInvalid({ commit }, invalid) {
      commit(PAR_ROUNDING_FORM_INVALID, invalid);
    },
    persistRoundingForm({ commit, state }) {
      commit(SET_ACCURACY, {
        final:
          state.accuracy.current === undefined
            ? state.accuracy.final
            : state.accuracy.current,
        current: undefined,
      });
      commit(SET_ROUNDING_TYPE, {
        final:
          state.roundingType.current === undefined
            ? state.roundingType.final
            : state.roundingType.current,
        current: undefined,
      });
      commit(SET_TARGET_TYPE, {
        final:
          state.targetType.current === undefined
            ? state.targetType.final
            : state.targetType.current,
        current: undefined,
      });
      commit(SET_UP_TO_PRICE, {
        final:
          state.upToPrice.current === undefined
            ? state.upToPrice.final
            : state.upToPrice.current,
        current: undefined,
      });
      commit(SET_DOWN_TO_PRICE, {
        final:
          state.downToPrice.current === undefined
            ? state.downToPrice.final
            : state.downToPrice.current,
        current: undefined,
      });
    },
    clearRoundingForm({ commit, state }, value = undefined) {
      commit(SET_ACCURACY, { ...state.accuracy, current: value });
      commit(SET_ROUNDING_TYPE, { ...state.roundingType, current: value });
      commit(SET_TARGET_TYPE, { ...state.targetType, current: value });
      commit(SET_UP_TO_PRICE, { ...state.upToPrice, current: value });
      commit(SET_DOWN_TO_PRICE, { ...state.downToPrice, current: value });
    },
  },
};
